import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react';
import { ModuleStatus } from 'types/ModuleStatus';
import { MODULE_STATUS } from 'constants/modules';
import {
  AuthorizationErrorResponse,
  SocialLoginDoneResponse,
} from 'types/SocialLogin';
import { SocialLoginError } from 'constants/socialLogin';
import Logger from 'utils/logger';
import SocialLoginApi from 'app/modules/socialLogin/api';
import { useQuery } from 'react-query';
import { getDefaultScopesByPageType } from 'app/modules/socialLogin/utils';
import { queryKey } from 'constants/queryKey';
import TimeUtils from 'app/utils/time';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { StringParam, useQueryParams } from 'use-query-params';
import { BrowserStorage } from 'utils/browserStorage';

type SocialLoginContextValue = {
  status: ModuleStatus;
  authorization: SocialLoginDoneResponse;
  error: AuthorizationErrorResponse | undefined;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  pageType: number | null;
  userKey: string | null | undefined;
  authorize: (options: {
    pageId?: number;
    pageType: number;
    scopes?: string[];
    redirectUrl?: string;
  }) => void;
  reset: () => void;
};

const SocialLoginContext = createContext<SocialLoginContextValue | undefined>(
  undefined,
);

const SocialLoginContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [status, setStatus] = useState<ModuleStatus>(MODULE_STATUS.Idle);
  const [authorization, setAuthorization] = useState<any>();
  const [error, setError] = useState<AuthorizationErrorResponse | undefined>();
  const [pageType, setPageType] = useLocalStorage<number | null>(
    BrowserStorage.keys.SocialLoginPageType,
    null,
  );
  const [query] = useQueryParams({
    userKey: StringParam,
  });

  const { isLoading: isFetchingPages } = useQuery(
    queryKey.socialLoginPages(query.userKey as string),
    () => SocialLoginApi.fetchPages(query.userKey as string),
    {
      refetchInterval: () => {
        return TimeUtils.calcMilliseconds.seconds(1);
      },
      refetchIntervalInBackground: true,
      enabled:
        !!query.userKey &&
        (status === MODULE_STATUS.Loading || status === MODULE_STATUS.Idle),
      onSuccess: (data) => {
        Logger.log('Recieved social login data', data);

        if (data) {
          if (data.status === 'done') {
            setStatus(MODULE_STATUS.Succeeded);
            setAuthorization({
              data: data.pages,
            });
          }
        }
      },
      onError: () => {
        const errorResponse = {
          authorizationOptions: authorizationOptions.current,
        };
        Logger.error(errorResponse);
        setError(errorResponse);
        setStatus(MODULE_STATUS.Failed);
      },
    },
  );
  const authorizationOptions = useRef<{
    pageType: number | null;
    scopes: string[];
  }>({
    pageType: null,
    scopes: [],
  });

  async function authorize(options: {
    pageType: number;
    pageId?: number;
    scopes?: string[];
    redirectUrl?: string;
  }) {
    try {
      const scopes = options.scopes
        ? options.scopes
        : getDefaultScopesByPageType(options.pageType);

      authorizationOptions.current.pageType = options?.pageType;
      authorizationOptions.current.scopes = scopes;

      if (!authorizationOptions.current.pageType) {
        throw new Error('Page type not defined');
      }

      setStatus(MODULE_STATUS.Loading);

      const socialLoginUrlResponse = await SocialLoginApi.requestLoginUrl(
        authorizationOptions.current.pageType,
        {
          scopes: authorizationOptions.current?.scopes,
          redirectUrl: options.redirectUrl || window.location.href,
        },
      );

      setPageType(options.pageType);
      window.location.href = socialLoginUrlResponse.loginUrl;
    } catch (e) {
      const error = {
        reason:
          (e instanceof Error && e.message) ||
          SocialLoginError.unableToAuthorize,
        authorizationOptions: authorizationOptions.current,
      };

      Logger.error(error);
      setError(error);
      setStatus(MODULE_STATUS.Failed);
    }
  }

  const reset = () => {
    setStatus(MODULE_STATUS.Idle);
    setPageType(null);
    setAuthorization(undefined);
    setError(undefined);
  };

  return (
    <SocialLoginContext.Provider
      value={{
        status,
        authorization,
        error,
        pageType,
        userKey: query.userKey,
        isSuccess: status === MODULE_STATUS.Succeeded,
        isLoading: status === MODULE_STATUS.Loading || isFetchingPages,
        isError: status === MODULE_STATUS.Failed,
        authorize,
        reset,
      }}
    >
      {children}
    </SocialLoginContext.Provider>
  );
};

export default SocialLoginContextProvider;

export const useSocialLoginContext = () => {
  const context = useContext(SocialLoginContext);

  if (!context) {
    throw new Error(
      'useSocialLoginContext must be used within a SocialLoginContextProvider',
    );
  }

  return context;
};
